@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
html, body{
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

#main{
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 1fr auto;
  height: 100vh;
  background: #f1f1f1;
}

::selection {
  background: #BBBBBB;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #main{
    display: block;
  }

  h2 {
    margin-top: 0;
    padding-top: 20px;
  }
}


/**
  HeaderWrap
**/
#HeaderWrap{
    background: #F1F1F1;
    position: relative;
}


/* Position and style the close button (top right corner) */
#HeaderWrap .closebtn {
  position: relative;
  top: 0;
  float: right;
  font-size: 46px;
  text-decoration: none;
  color: #818181;
  z-index: 10;
  transition: 0.5s;
  margin: 0;
  margin-right: 20px;
  cursor: pointer;
}

/* Position and style the open button (top right corner) */
#HeaderWrap .openbtn {
  position: fixed;
  top: 0;
  left: 25px;
  font-size: 36px;
  text-decoration: none;
  color: #818181;
  z-index: 1;
  transition: 0.5s;
}


/**
  Header
**/

/*Positioning*/

#Header{
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2; /* Stay on top */
  top: 0;
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}


/*Look*/
#Header{
  background-color: #E6E6E6;
  color: #000000;
  cursor: default;
  min-height: 100vh;
  box-shadow: 5px 5px 8px #888888;
}

#Header h1{
  padding: 0;
  margin-left: 30px;
  font-size: 50px;
}

#Header a{
  text-decoration: none;
  color: black;
  cursor: default;
  margin-left: 30px;
}

#subtitle{
    margin: 0px;
    margin-left: 30px;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #Header{
    height: 0; /* change javascript */
    width: 100%; /* 100% width */
    position: fixed; /* Stay in place */
    z-index: 2; /* Stay on top */
    top: 0px;
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  }
  /*Look*/
  #Header{
    box-shadow: 0px 5px 8px #888888;
    min-height: 0vh;
  }

  #HeaderWrap .openbtn {
    position: fixed;
    top: 0;
    margin-top: 5px;
    right: 20px;
    left: auto;
  }
}

#Menu{
  display: grid;
}

#Menu a{
  text-decoration: none;
  color: black;
  cursor: default;
}

/**

@-webkit-keyframes rotateY {
  to { -webkit-transform: rotateY(360deg); }
}
@keyframes rotateY {
  to { transform: rotateY(360deg); }
}

#Menu h2:hover{
  -webkit-animation: rotateY 1s linear;
  animation: rotateY .5s linear;
}
**/


@media only screen and (max-width: 720px){
  /*Positioning*/
  #Menu{
    grid-template-columns: 33% 33% 33%;
  }

  #Menu h2{
    font-size: 12px;
  }
}


#Start{
    padding-top: 7vh;
    text-align: center;
    margin-left: -8%;
    display: grid;
    justify-items: center;
    grid-template-columns: 8vw 8vw 8vw 8vw 8vw 8vw 8vw 8vw 8vw;
    grid-template-rows: 16vh 16vh 16vh 16vh 16vh;
    height: 90vh;
    grid-row-gap: 3vh;
}

.imgCell{
    text-align: center;
    cursor: default;
    border: 0px solid #000000;
    box-shadow: 1px 0px 2px #888888;
    z-index: 0;
    height: 65%;
    overflow: hidden;
}

.gridCell{
    opacity: 0;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
}

.gridCell:hover{
    opacity: 1;
}

.turnPositive{
    transform: rotate(90deg);
}

.turnNegative{
    transform: rotate(-90deg);
}

#Start h2{
    vertical-align: middle;
    display: table-cell;
    grid-column: 3 / 8;
    font-size: 64px;
    margin: 0px;
    letter-spacing: 16px;
    margin-bottom: 10px;
}

.hideOnDesktop{
    display: none;
}

.hideOnMobile{
    display: block;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #Start{
    margin-left: 2vw;
    height: 80vh;
    width: 96vw;
    padding-top: 15vh;

    display: grid;
    justify-items: center;
    grid-template-columns: 24vw 24vw 24vw 24vw;
    grid-template-rows: 12vh 12vh 12vh 12vh 12vh 12vh;
    grid-row-gap: 1.7vh;
  }

  .gridCell{
    opacity: 1;
  }

  .imgCell{
      text-align: center;
      cursor: default;
      border: 0px solid #000000;
      box-shadow: 1px 0px 2px #888888;
      z-index: 0;
      height: 55%;
      overflow: hidden;
  }

  #Start h2{
      vertical-align: middle;
      display: table-cell;
      grid-column: 1 / 5;
      font-size: 24px;
      margin: 0px;
      letter-spacing: 8px;
      margin-bottom: 10px;
  }

  .hideOnMobile{
    display: none;
  }

  .hideOnDesktop{
    display: block;
  }

  .overTwoOnMobile{
    grid-column: 2 / 4;
  }

  .padLeftOnMobile{
    height: 140%;
  }

  .padLeftOnMobile > img{
    margin-top: -1.4vh;
    margin-right: -15vw;
  }

  .padRightOnMobile > img{
    margin-top: -1.4vh;
    margin-left: -15vw;
  }

  .padRightOnMobile{
    height: 140%;
  }



}
.previewArea{
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.7);
  z-index: 20;
}

.previewPopUp{
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: 50%;
  left: 35%;
  height: 90%;
  max-height: 90%;
  top: 5%;
  background-color: white;
  z-index: 100;
  opacity: 1 !important;
  overflow: scroll;
  background-color: #F1F1F1;
}

.headerPopUp{
    width: 100%;
    background: #E1E1E1;
    height: 64px;
}

.itemPreview{
  opacity: 1;
  cursor: default;
  padding: 10px;
}


.itemPreview:hover{

}

.itemPreviewPic{
  width: 50vh;
}


#previewTitleList{
  grid-column-start: 1;
  align-self: end;
  text-align: center;
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 60%;
  max-width: 60%;
}

#previewTitleList p{
    margin-top: 0;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  z-index: 25;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 16px;
  content: ' ';
  height: 32px;
  width: 4px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}


@media only screen and (max-width: 720px){
  /*Positioning*/
  .previewPopUp{
    overflow-y: scroll;
    width: 80%;
    left: 10%;
    height: 70%;
    top: 15%;
    grid-template-columns: 50% 50%;
  }

  .itemPreview{
    opacity: 1;
  }

  .close{
    opacity: 1;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
  }

  .close:before, .close:after {
    left: 12px;
    content: ' ';
    height: 24px;
    width: 4px;
    background-color: #333;
  }

  .headerPopUp{
      height: 48px;
  }

  .itemPreviewPic{
    width: 60vw;
  }

}

@-webkit-keyframes prevHover {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes prevHover {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}


/**
  GalleryGrid
**/

#Gallery{
  text-align: center;
  background: #F1F1F1;
}

/*Positioning*/
#GalleryGrid{
  text-align: center;
  display: grid;
  justify-items: center;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 10px;
}

/*Look*/
#GalleryGrid{
  padding-top: 20px;
  background: #F1F1F1;
}

/**
  GalleryItem
  350x484 = 0.723
  596x842 = 0.708
**/

.GalleryItem{
  text-align: center;
  cursor: default;
  margin: 15px;
  border: 0px solid #000000;
  box-shadow: 1px 0px 2px #888888;

}


.GalleryItem hr{
  margin: 1px;
  border: 0px solid #000000;
}

.GalleryItem p{
    margin-top: 20px;
}

.GalleryItemPicFrame{
  overflow: hidden;
  height: 230px;
  width: 230px;
}

.GalleryItem:hover .previewImage{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s linear;
}

.previewImage{
    display: block;
    opacity: 0.3;
    max-width: 210px;
    margin-bottom: -220px;
    margin-left: 10px;
    height: 300px;
}

.GalleryItemPic:hover{
  -webkit-animation: picHover forwards 1.5s ease-out;
          animation: picHover forwards 1.5s ease-out;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #GalleryGrid{
    grid-template-columns: 50% 50%;
  }

  .GalleryItemPicFrame{
    overflow: hidden;
    height: 210px;
    width: 160px;
  }

  .previewImage{
    max-width: 140px;
    height: 230px;
    opacity: 1;
  }
}


@-webkit-keyframes picHover {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}


@keyframes picHover {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

#Biography{
  text-align: center;
  background: #F1F1F1;
  padding: 20px;
}

#Technik{
  text-align: center;
  background: #F1F1F1;
  padding: 20px;
}

.gridImgs{
    padding-top: 10vh;
    display: grid;
    justify-items: center;
    grid-template-columns: 40vw 40vw;
    grid-row-gap: 3vh;
}

.gridPicTop{
    width: 30vw;
}

.gridPic{
    width: 30vw;
}
#Zeichnungen{
  text-align: center;
  background: #F1F1F1;
}

#drawings{
  display: grid;
  grid-template-columns: 14.285% 14.285% 14.285% 14.285% 14.285% 14.285% 14.285%;
  padding: 10px;
}

.DrawingItem{
}

.DrawingItemPic{
  width: 148px;
  height: 200px;
  object-fit: cover;
  object-position: 90% 0;
  padding: 10px;
  opacity: 0;
}

.bottomDrawingLeft{
    grid-column: 2 / 4;
    width: 324px;
    height: 217px;
}

.bottomDrawingPicLeft{
    width: 324px;
    height: 217px;
}

.bottomDrawingRight{
    grid-column: 5 / 7;
    width: 324px;
    height: 217px;
}

.bottomDrawingPicRight{
    width: 324px;
    height: 217px;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #drawings{
    grid-template-columns: 50% 50%;
  }

  .bottomDrawingLeft{
      grid-column: 1;
      width: 148px;
      height: 99px;
      margin-left: 15px;
  }

  .bottomDrawingPicLeft{
      width: 148px;
      height: 99px;
  }

  .bottomDrawingRight{
      grid-column: 2;
      width: 148px;
      height: 99px;
      margin-left: 15px;
  }

  .bottomDrawingPicRight{
      width: 148px;
      height: 99px;
  }
}

.animate{
    opacity: 0;
    -webkit-animation: in 2s forwards;
}

@-webkit-keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#Ausstellungen{
  text-align: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-row-gap: 30px;
  background: #F1F1F1;
}

#preise{
  display: inline-grid;
  grid-template-rows: 100px 50px;
  grid-template-columns: auto;
}

#ankaeufe{
  display: inline-grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 10% 40% 40% 10%;
  text-align: center;
}

#ankaeufe>h2{
  grid-column: 1/5;
  grid-row: 1
}

#ankaeufe>#ankaeufePrivat{
  grid-row: 2;
  grid-column: 2;
}
#ankaeufe>#ankaeufeOeffentlich{
  grid-row: 2;
  grid-column: 3;
}

#einzelAusstellungen{
  display: inline-grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 20% 60% 20%;
  text-align: center;
}

#einzelAusstellungen>h2{
  grid-column: 1/4;
  grid-row: 1
}

#einzelAusstellungen>ul{
  list-style: none;
  grid-row: 2;
  grid-column: 2;
  text-align: left;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

#gemeinschaftsAusstellungen{
  display: inline-grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 20% 60% 20%;
  text-align: center;
}

#gemeinschaftsAusstellungen>h2{
  grid-column: 1/4;
  grid-row: 1
}

#gemeinschaftsAusstellungen>ul{
  list-style: none;
  grid-row: 2;
  grid-column: 2;
  text-align: left;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

#Kontakt{
  text-align: center;
  padding-left: 5em;
  padding-right: 5em;
  background: #F1F1F1;
}


/**
  Footer
**/

/*Positioning*/
#Footer{
  position: relative;
  bottom: 0px;
  text-align: center;
  grid-column: 2;
  margin-left: -25%;
  font-size: 12px;
}

/*Look*/
#Footer{
  background: #F1F1F1;
}

@media only screen and (max-width: 720px){

    #Footer{
        display: none;
    }

}
