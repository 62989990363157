
/**
  Footer
**/

/*Positioning*/
#Footer{
  position: relative;
  bottom: 0px;
  text-align: center;
  grid-column: 2;
  margin-left: -25%;
  font-size: 12px;
}

/*Look*/
#Footer{
  background: #F1F1F1;
}

@media only screen and (max-width: 720px){

    #Footer{
        display: none;
    }

}