#Technik{
  text-align: center;
  background: #F1F1F1;
  padding: 20px;
}

.gridImgs{
    padding-top: 10vh;
    display: grid;
    justify-items: center;
    grid-template-columns: 40vw 40vw;
    grid-row-gap: 3vh;
}

.gridPicTop{
    width: 30vw;
}

.gridPic{
    width: 30vw;
}