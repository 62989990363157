@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

html, body{
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

#main{
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 1fr auto;
  height: 100vh;
  background: #f1f1f1;
}

::selection {
  background: #BBBBBB;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #main{
    display: block;
  }

  h2 {
    margin-top: 0;
    padding-top: 20px;
  }
}
