
/**
  HeaderWrap
**/
#HeaderWrap{
    background: #F1F1F1;
    position: relative;
}


/* Position and style the close button (top right corner) */
#HeaderWrap .closebtn {
  position: relative;
  top: 0;
  float: right;
  font-size: 46px;
  text-decoration: none;
  color: #818181;
  z-index: 10;
  transition: 0.5s;
  margin: 0;
  margin-right: 20px;
  cursor: pointer;
}

/* Position and style the open button (top right corner) */
#HeaderWrap .openbtn {
  position: fixed;
  top: 0;
  left: 25px;
  font-size: 36px;
  text-decoration: none;
  color: #818181;
  z-index: 1;
  transition: 0.5s;
}


/**
  Header
**/

/*Positioning*/

#Header{
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2; /* Stay on top */
  top: 0;
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}


/*Look*/
#Header{
  background-color: #E6E6E6;
  color: #000000;
  cursor: default;
  min-height: 100vh;
  box-shadow: 5px 5px 8px #888888;
}

#Header h1{
  padding: 0;
  margin-left: 30px;
  font-size: 50px;
}

#Header a{
  text-decoration: none;
  color: black;
  cursor: default;
  margin-left: 30px;
}

#subtitle{
    margin: 0px;
    margin-left: 30px;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #Header{
    height: 0; /* change javascript */
    width: 100%; /* 100% width */
    position: fixed; /* Stay in place */
    z-index: 2; /* Stay on top */
    top: 0px;
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  }
  /*Look*/
  #Header{
    box-shadow: 0px 5px 8px #888888;
    min-height: 0vh;
  }

  #HeaderWrap .openbtn {
    position: fixed;
    top: 0;
    margin-top: 5px;
    right: 20px;
    left: auto;
  }
}
