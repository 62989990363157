#Menu{
  display: grid;
}

#Menu a{
  text-decoration: none;
  color: black;
  cursor: default;
}

/**

@-webkit-keyframes rotateY {
  to { -webkit-transform: rotateY(360deg); }
}
@keyframes rotateY {
  to { transform: rotateY(360deg); }
}

#Menu h2:hover{
  -webkit-animation: rotateY 1s linear;
  animation: rotateY .5s linear;
}
**/


@media only screen and (max-width: 720px){
  /*Positioning*/
  #Menu{
    grid-template-columns: 33% 33% 33%;
  }

  #Menu h2{
    font-size: 12px;
  }
}

