#Ausstellungen{
  text-align: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-row-gap: 30px;
  background: #F1F1F1;
}

#preise{
  display: inline-grid;
  grid-template-rows: 100px 50px;
  grid-template-columns: auto;
}

#ankaeufe{
  display: inline-grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 10% 40% 40% 10%;
  text-align: center;
}

#ankaeufe>h2{
  grid-column: 1/5;
  grid-row: 1
}

#ankaeufe>#ankaeufePrivat{
  grid-row: 2;
  grid-column: 2;
}
#ankaeufe>#ankaeufeOeffentlich{
  grid-row: 2;
  grid-column: 3;
}

#einzelAusstellungen{
  display: inline-grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 20% 60% 20%;
  text-align: center;
}

#einzelAusstellungen>h2{
  grid-column: 1/4;
  grid-row: 1
}

#einzelAusstellungen>ul{
  list-style: none;
  grid-row: 2;
  grid-column: 2;
  text-align: left;
  padding-inline-start: 0px;
}

#gemeinschaftsAusstellungen{
  display: inline-grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 20% 60% 20%;
  text-align: center;
}

#gemeinschaftsAusstellungen>h2{
  grid-column: 1/4;
  grid-row: 1
}

#gemeinschaftsAusstellungen>ul{
  list-style: none;
  grid-row: 2;
  grid-column: 2;
  text-align: left;
  padding-inline-start: 0px;
}
