.previewArea{
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.7);
  z-index: 20;
}

.previewPopUp{
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: 50%;
  left: 35%;
  height: 90%;
  max-height: 90%;
  top: 5%;
  background-color: white;
  z-index: 100;
  opacity: 1 !important;
  overflow: scroll;
  background-color: #F1F1F1;
}

.headerPopUp{
    width: 100%;
    background: #E1E1E1;
    height: 64px;
}

.itemPreview{
  opacity: 1;
  cursor: default;
  padding: 10px;
}


.itemPreview:hover{

}

.itemPreviewPic{
  width: 50vh;
}


#previewTitleList{
  grid-column-start: 1;
  align-self: end;
  text-align: center;
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 60%;
  max-width: 60%;
}

#previewTitleList p{
    margin-top: 0;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  z-index: 25;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 16px;
  content: ' ';
  height: 32px;
  width: 4px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}


@media only screen and (max-width: 720px){
  /*Positioning*/
  .previewPopUp{
    overflow-y: scroll;
    width: 80%;
    left: 10%;
    height: 70%;
    top: 15%;
    grid-template-columns: 50% 50%;
  }

  .itemPreview{
    opacity: 1;
  }

  .close{
    opacity: 1;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
  }

  .close:before, .close:after {
    left: 12px;
    content: ' ';
    height: 24px;
    width: 4px;
    background-color: #333;
  }

  .headerPopUp{
      height: 48px;
  }

  .itemPreviewPic{
    width: 60vw;
  }

}

@keyframes prevHover {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
