#Zeichnungen{
  text-align: center;
  background: #F1F1F1;
}

#drawings{
  display: grid;
  grid-template-columns: 14.285% 14.285% 14.285% 14.285% 14.285% 14.285% 14.285%;
  padding: 10px;
}

.DrawingItem{
}

.DrawingItemPic{
  width: 148px;
  height: 200px;
  object-fit: cover;
  object-position: 90% 0;
  padding: 10px;
  opacity: 0;
}

.bottomDrawingLeft{
    grid-column: 2 / 4;
    width: 324px;
    height: 217px;
}

.bottomDrawingPicLeft{
    width: 324px;
    height: 217px;
}

.bottomDrawingRight{
    grid-column: 5 / 7;
    width: 324px;
    height: 217px;
}

.bottomDrawingPicRight{
    width: 324px;
    height: 217px;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #drawings{
    grid-template-columns: 50% 50%;
  }

  .bottomDrawingLeft{
      grid-column: 1;
      width: 148px;
      height: 99px;
      margin-left: 15px;
  }

  .bottomDrawingPicLeft{
      width: 148px;
      height: 99px;
  }

  .bottomDrawingRight{
      grid-column: 2;
      width: 148px;
      height: 99px;
      margin-left: 15px;
  }

  .bottomDrawingPicRight{
      width: 148px;
      height: 99px;
  }
}

.animate{
    opacity: 0;
    -webkit-animation: in 2s forwards;
}

@-webkit-keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
