#Start{
    padding-top: 7vh;
    text-align: center;
    margin-left: -8%;
    display: grid;
    justify-items: center;
    grid-template-columns: 8vw 8vw 8vw 8vw 8vw 8vw 8vw 8vw 8vw;
    grid-template-rows: 16vh 16vh 16vh 16vh 16vh;
    height: 90vh;
    grid-row-gap: 3vh;
}

.imgCell{
    text-align: center;
    cursor: default;
    border: 0px solid #000000;
    box-shadow: 1px 0px 2px #888888;
    z-index: 0;
    height: 65%;
    overflow: hidden;
}

.gridCell{
    opacity: 0;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
}

.gridCell:hover{
    opacity: 1;
}

.turnPositive{
    transform: rotate(90deg);
}

.turnNegative{
    transform: rotate(-90deg);
}

#Start h2{
    vertical-align: middle;
    display: table-cell;
    grid-column: 3 / 8;
    font-size: 64px;
    margin: 0px;
    letter-spacing: 16px;
    margin-bottom: 10px;
}

.hideOnDesktop{
    display: none;
}

.hideOnMobile{
    display: block;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #Start{
    margin-left: 2vw;
    height: 80vh;
    width: 96vw;
    padding-top: 15vh;

    display: grid;
    justify-items: center;
    grid-template-columns: 24vw 24vw 24vw 24vw;
    grid-template-rows: 12vh 12vh 12vh 12vh 12vh 12vh;
    grid-row-gap: 1.7vh;
  }

  .gridCell{
    opacity: 1;
  }

  .imgCell{
      text-align: center;
      cursor: default;
      border: 0px solid #000000;
      box-shadow: 1px 0px 2px #888888;
      z-index: 0;
      height: 55%;
      overflow: hidden;
  }

  #Start h2{
      vertical-align: middle;
      display: table-cell;
      grid-column: 1 / 5;
      font-size: 24px;
      margin: 0px;
      letter-spacing: 8px;
      margin-bottom: 10px;
  }

  .hideOnMobile{
    display: none;
  }

  .hideOnDesktop{
    display: block;
  }

  .overTwoOnMobile{
    grid-column: 2 / 4;
  }

  .padLeftOnMobile{
    height: 140%;
  }

  .padLeftOnMobile > img{
    margin-top: -1.4vh;
    margin-right: -15vw;
  }

  .padRightOnMobile > img{
    margin-top: -1.4vh;
    margin-left: -15vw;
  }

  .padRightOnMobile{
    height: 140%;
  }



}