
/**
  GalleryGrid
**/

#Gallery{
  text-align: center;
  background: #F1F1F1;
}

/*Positioning*/
#GalleryGrid{
  text-align: center;
  display: grid;
  justify-items: center;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 10px;
}

/*Look*/
#GalleryGrid{
  padding-top: 20px;
  background: #F1F1F1;
}

/**
  GalleryItem
  350x484 = 0.723
  596x842 = 0.708
**/

.GalleryItem{
  text-align: center;
  cursor: default;
  margin: 15px;
  border: 0px solid #000000;
  box-shadow: 1px 0px 2px #888888;

}


.GalleryItem hr{
  margin: 1px;
  border: 0px solid #000000;
}

.GalleryItem p{
    margin-top: 20px;
}

.GalleryItemPicFrame{
  overflow: hidden;
  height: 230px;
  width: 230px;
}

.GalleryItem:hover .previewImage{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s linear;
}

.previewImage{
    display: block;
    opacity: 0.3;
    max-width: 210px;
    margin-bottom: -220px;
    margin-left: 10px;
    height: 300px;
}

.GalleryItemPic:hover{
  animation: picHover forwards 1.5s ease-out;
}

@media only screen and (max-width: 720px){
  /*Positioning*/
  #GalleryGrid{
    grid-template-columns: 50% 50%;
  }

  .GalleryItemPicFrame{
    overflow: hidden;
    height: 210px;
    width: 160px;
  }

  .previewImage{
    max-width: 140px;
    height: 230px;
    opacity: 1;
  }
}


@keyframes picHover {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
